export default [

    // {
    //     header: 'label_pages',
    // },
    {
        title: 'label_dashboard',
        icon: 'HomeIcon',
        // tag: '2', // counter events for menu
        // tagVariant: 'light-warning',
        route: 'dashboard'
    },
    // CONTACT
    {
        title: 'label_potential_clients',
        icon: 'PhoneIncomingIcon',
        // action: 'view',
        // resource: 'contact.tab_details',
        children: [
            {
                title: 'label_potential_clients',
                route: 'contacts',
                action: 'view',
                resource: 'contact.tab_details',
            },
            {
                title: 'label_new_clients',
                route: 'contacts-new',
                action: 'view',
                resource: 'contact.tab_details',
            },
            {
                title: 'label_clients_in_progress',
                route: 'contacts-in-progress',
                action: 'view',
                resource: 'contact.tab_details',
            },
        ],
    },
    {
        title: 'label_agreements',
        icon: 'TrelloIcon',
        // action: 'view',
        // resource: 'agreement.tab_details',
        children: [
            {
                title: 'label_agreements_list',
                route: 'agreements',
                action: 'view',
                resource: 'agreement.tab_details',
            },
            {
                title: 'label_add_agreement',
                route: 'agreement_add',
                action: 'edit',
                resource: 'agreement.tab_details',
            },
        ],
    },
    {
        title: 'label_deals',
        icon: 'BriefcaseIcon',
        // action: 'view',
        // resource: 'deal.tab_details',
        children: [
            {
                title: 'label_list',
                route: 'deals',
                action: 'view',
                resource: 'deal.tab_details',
            },
            {
                title: 'label_add_deal',
                route: 'deal_add',
                action: 'edit',
                resource: 'deal.tab_details',
            },

        ],
    },   {
        title: 'label_mail',
        icon: 'MailIcon',
        // action: 'view',
        // resource: 'mail.tab_details',
        children: [
            {
                title: 'label_undecided',
                route: 'mails_undecided',
                action: 'view',
                resource: 'mail.tab_details',
            },
            {
                title: 'label_incoming',
                route: 'mails_incoming',
                action: 'view',
                resource: 'mail.tab_details',
            },
            {
                title: 'label_outcoming',
                route: 'mails_outcoming',
                action: 'view',
                resource: 'mail.tab_details',
            },
            {
                title: 'label_senders_and_recipients_short',
                route: 'mail_members',
                action: 'view',
                resource: 'mail.tab_details',
            },

        ],
    },
    {
        title: 'label_tasks',
        icon: 'CheckSquareIcon',
        route: 'tasks',
        action: 'view',
        resource: 'deal.tab_tasks',

    },
    {
        title: 'label_working_time',
        icon: 'ClockIcon',
        route: 'working_time',
        action: 'view',
        resource: 'workingtime.tab_details',
    },
    {
        title: 'label_contractual_payments',
        icon: 'CreditCardIcon',
        route: 'payments',
        action: 'view',
        resource: 'agreement.tab_payments',

    },
    {
        title: 'label_receivables',
        icon: 'ZapIcon',
        route: 'receivables',
        action: 'view',
        resource: 'agreement.tab_payments'
    },
    {
        title: 'label_procurations',
        icon: 'PocketIcon',
        route: 'procurations',
        action: 'view',
        resource: 'procurations.tab_details'
    },
    {
        title: 'label_agreement_register',
        icon: 'BookIcon',
        // action: 'view',
        // resource: 'contractregistry.tab_details',
        children: [
            {
                title: 'label_agreements_list',
                route: 'contract_registry',
                action: 'view',
                resource: 'contractregistry.tab_details',
            },
            {
                title: 'label_category_list',
                route: 'contract_categories',
                action: 'view',
                resource: 'contractregistry.tab_details',
            },
        ],
    },
    {
        title: 'label_contact_register',
        icon: 'BookIcon',
        // action: 'view',
        // resource: 'contactregistry.tab_details',
        children: [
            {
                title: 'label_contact_list',
                route: 'contact_registry',
                action: 'view',
                resource: 'contactregistry.tab_details',
            },
            {
                title: 'label_category_list',
                route: 'contact_categories',
                action: 'view',
                resource: 'contactregistry.tab_details',
            },

        ],
    },
    {
        title: 'label_agents_invoices',
        icon: 'FileTextIcon',
        route: 'reports',
        action: 'view',
        resource: 'report.agents_invoices',
    },
    {
        title: 'label_calendar',
        icon: 'CalendarIcon',
        route: 'calendar',
        action: 'view',
        resource: 'calendar.tab_details',
    },
    {
        title: 'label_chat',
        icon: 'TwitchIcon',
        route: 'chat',
        action: 'view',
        resource: 'chat.tab_details',
    },
    {
        title: 'label_admins',
        icon: 'UserIcon',
        // action: 'view',
        // resource: 'subadmin.tab_details',
        children: [
            {
                title: 'label_list',
                route: 'admins',
                action: 'view',
                resource: 'subadmin.tab_details',
            },
            {
                title: 'label_new',
                route: 'admins_add',
                action: 'view',
                resource: 'subadmin.tab_details',
            },
        ],
    },
    {
        title: 'label_solicitors',
        icon: 'UserIcon',
        // action: 'view',
        // resource: 'solicitor.tab_details',
        children: [
            {
                title: 'label_list',
                route: 'solicitors',
                action: 'view',
                resource: 'solicitor.tab_details',
            },
            {
                title: 'label_new',
                route: 'solicitors_add',
                action: 'edit',
                resource: 'solicitor.tab_details',
            },

        ],
    },
    {
        title: 'label_consultants',
        icon: 'UserIcon',
        // action: 'view',
        // resource: 'consultant.tab_details',
        children: [
            {
                title: 'label_list',
                route: 'consultants',
                action: 'view',
                resource: 'consultant.tab_details',
            },
            {
                title: 'label_new',
                route: 'consultant_add',
                action: 'edit',
                resource: 'consultant.tab_details',
            },

        ],
    },
    {
        title: 'label_accountants',
        icon: 'UserIcon',
        // action: 'view',
        // resource: 'accountant.tab_details',
        children: [
            {
                title: 'label_list',
                route: 'accountants',
                action: 'view',
                resource: 'accountant.tab_details',
            },
            {
                title: 'label_new',
                route: 'accountant_add',
                action: 'edit',
                resource: 'accountant.tab_details',
            },

        ],
    },
    {
        title: 'label_secretaries',
        icon: 'UserIcon',
        // action: 'view',
        // resource: 'secretary.tab_details',
        children: [
            {
                title: 'label_list',
                route: 'secretaries_list',
                action: 'view',
                resource: 'secretary.tab_details',
            },
            {
                title: 'label_new',
                route: 'secretaries_new',
                action: 'edit',
                resource: 'secretary.tab_details',
            },

        ],
    },

    {
        title: 'label_recommended',
        icon: 'UserIcon',
        // action: 'view',
        // resource: 'agent.core',
        children: [
            {
                title: 'label_list',
                route: 'agents',
                action: 'view',
                resource: 'agent.tab_details',
            },
            {
                title: 'label_new',
                route: 'agents_add',
                action: 'edit',
                resource: 'agent.tab_details',
            },

        ],
    },
    {
        title: 'label_menu_pattern',
        icon: 'BoxIcon',
        // action: 'view',
        // resource: 'pattern.core',
        children: [
            {
                title: 'label_list',
                route: 'patterns',
                action: 'view',
                resource: 'pattern.tab_details',
            },
            {
                title: 'label_new',
                route: 'pattern_add',
                action: 'edit',
                resource: 'pattern.tab_details',
            },
            {
                title: 'label_category_list',
                route: 'pattern_categories',
                action: 'view',
                resource: 'pattern.tab_details',
            },
            {
                title: 'label_logo',
                route: 'pattern_logo',
                action: 'view',
                resource: 'pattern.tab_details',
            },
            {
                title: 'label_footer',
                route: 'pattern_footer',
                action: 'view',
                resource: 'pattern.tab_details',
            },

        ],
    },
    {
        title: 'label_password_manager',
        icon: 'UnlockIcon',
        // action: 'view',
        resource: 'password_manager.tab_details',
        children: [
            {
                title: 'label_password_manager',
                route: 'password_manager',
                action: 'view',
                resource: 'password_manager.tab_details',
            },
            {
                title: 'label_category_list',
                route: 'password_manager_categories',
                action: 'view',
                resource: 'password_manager.tab_details',
            },
        ],
    },
    {
        title: 'label_menu_role_access',
        icon: 'ShieldIcon',
        route: 'role_access',
        action: 'view',
        resource: 'role_access.tab_details',
    },
    {
        title: 'label_mlm_structure',
        icon: 'AlignRightIcon',
        route: 'mlm_structure',
        action: 'view',
        resource: 'mlm.tab_details',
    },
    {
        title: 'label_departments',
        icon: 'AlignRightIcon',
        route: 'departments',
        action: 'view',
        resource: 'departments.tab_details',
    },

    // referral
    {
        title: 'label_marketing',
        icon: 'LinkIcon',
        // action: 'view',
        // resource: 'partner.referral',
        children: [
            {
                title: 'label_referral_contacts',
                route: 'referral_contacts',
                action: 'view',
                resource: 'partner.referral',
            },
            {
                title: 'label_referral_stats',
                route: 'referral_stats',
                action: 'view',
                resource: 'partner.referral_stats',
            },
        ],
    },

    {
        title: 'label_language',
        icon: 'VolumeIcon',
        route: 'languages',
        action: 'view',
        resource: 'language.tab_details',
    },
    {
        title: 'label_settings',
        icon: 'SettingsIcon',
        // action: 'view',
        // resource: 'setting',
        children: [
            {
                title: 'label_theme_styles',
                route: 'setting_theme_styles',
                action: 'edit',
                resource: 'setting',
            },
            {
                title: 'label_client_settings',
                route: 'setting_selects',
                action: 'edit',
                resource: 'setting',
            },
            {
                title: 'label_invoice_details',
                route: 'setting_invoice_details',
                action: 'edit',
                resource: 'setting',
            },
            {
                title: 'label_sms',
                icon: 'SendIcon',
                action: 'view',
                resource: 'sms_patterns',
                children: [
                    {
                        title: 'label_sms_patterns',
                        route: 'sms_patterns',
                        action: 'view',
                        resource: 'sms_patterns',
                    },
                    {
                        title: 'label_new',
                        route: 'sms_pattern_add',
                        action: 'edit',
                        resource: 'sms_patterns',
                    },
                    {
                        title: 'label_category_list',
                        route: 'sms_pattern_categories',
                        action: 'view',
                        resource: 'sms_patterns',
                    },
                    // {
                    //     title: 'label_sms_settings',
                    //     route: 'sms_settings',
                    //     action: 'edit',
                    //     resource: 'setting',
                    // },
                ],
            },
            {
                title: 'label_universal_email_footer',
                route: 'setting_email_footer',
                action: 'edit',
                resource: 'setting',
            },
            {
                title: 'label_statements',
                route: 'setting_statements',
                action: 'edit',
                resource: 'setting',
            },
            {
                title: 'label_worktime_rounding',
                route: 'setting_worktime_rounding',
                action: 'edit',
                resource: 'setting',
            },
            {
                title: 'label_system_gmail_address',
                route: 'setting_system_gmail',
                action: 'edit',
                resource: 'setting',
            },

            {
                title: 'label_password_reset_email',
                route: 'setting_password_reset_email',
                action: 'edit',
                resource: 'setting',
            },
            {
                title: 'label_email_to_respons_user_desc',
                route: 'setting_client_email_to_responsible_user',
                action: 'edit',
                resource: 'setting',
            },
            {
                title: 'label_notification_to_client_desc',
                route: 'setting_notification_to_client_about_mail',
                action: 'edit',
                resource: 'setting',
            },
            {
                title: 'label_email_for_meetings',
                route: 'setting_email_for_meeting',
                action: 'edit',
                resource: 'setting.tab_details',
            },
            {
                title: 'label_email_agreement_deadline',
                route: 'setting_email_agreement_deadline',
                action: 'edit',
                resource: 'setting.tab_details',
            },

            {
                title: 'label_email_automatic_password_change',
                route: 'setting_password_change_email',
                action: 'edit',
                resource: 'setting.tab_details',
            },
            {
                title: 'label_logotypes_and_favicon',
                route: 'setting_system_images',
                action: 'edit',
                resource: 'setting.tab_details',
            },
            {
                title: 'menu_failed_login_attempts',
                route: 'failed_login_attempts',
                action: 'edit',
                resource: 'setting.tab_details',
            },

        ],
    },
]
